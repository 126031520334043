html {
  scroll-behavior: auto !important;
  overflow-y: scroll;
}

/* general classes  */

.noBullet {
  list-style: none;
}

.noPadding {
  padding: 0;
}

.noBorderCustomCard {
  border: none;
}

.blackText {
  color: #000000;
}

.blueText {
  color: #86abd4;
}

/* .blueText {
  color: #65afff;
} */

.blueBackground {
  /* background-color: #0000ff; */
  border-bottom: #0000ff75 2px solid;
}

.redBackground {
  /* background-color: #ff0000; */
  border-bottom: #ff000075 2px solid;
}

.onePointFiveRem {
  font-size: 1.5rem;
}

.heightOneRem {
  height: 1rem;
}

.lightGreyText {
  color: #778899;
}

.zeroPointEightRem {
  font-size: 0.8rem;
}

.displayLineBreaks {
  white-space: pre-line;
}

.standardColorBackground {
  background-color: #86abd4;
}

/* Remove background colors on accordion  */

.blueBorder {
  border: #0000ff25 1px solid;
}

.redBorder {
  border: #ff000025 1px solid;
}

.purpleBorder {
  border: #ec00b825 1px solid;
}

.accordion-button:focus {
  box-shadow: none;
}

.accordion-button:not(.collapsed) {
  box-shadow: none;
  background-color: transparent;
}

/* Remove blue outline and box shadow from Bootstrap form elements */

.form-control:focus,
.btn:focus {
  outline: none;
  box-shadow: none;
  border-color: #ccc;
}

.greenBorder {
  border: #2477bf50 1px solid;
}

.pagination .page-item:not(.active) .page-link {
  color: #65afff !important;
}

.pagination .page-item.active .page-link {
  background-color: #2477bf !important;
  border: #2477bf 1px solid !important;
}

.btn-primary,
.btn-primary:hover,
.btn-primary:active,
.btn-primary:visited {
  background-color: #2477bf !important;
  border: #2477bf 1px solid !important;
}

.backButtonHover:hover {
  text-decoration: underline !important;
  color: #000000 !important;
}

.minListHeight {
  min-height: 2000px;
}

.modal-90w {
  width: 80%;
  max-width: 80%;
}

body.modal-open {
  padding-right: 0px !important;
  margin-right: 0px !important;
}
